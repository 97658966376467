@import './node_modules/@sbt-suite/components/theme/variables.scss';
@import './node_modules/@sbt-suite/components/theme/globalClasses.scss';

:root{
    --primary-color: #8cd2ff;
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    * {
        box-sizing: border-box;
    }
}
